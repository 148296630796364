const RouteConstants = {
	HOME: "/home",
	LOGIN: "/login",
	SIGNUP: "/signup",
	CALLBACK: "/callback-url",
	product: "/product",
	LANDING: "/",
	MYACCOUNT: "/myAccount",
	CART: "/cart",
	FEED: "/feed",
	PRICE: "/price",
	PAYMENT_GATEWAY: "/payment-gateway",
	PRIVACY: "/privacy",
	CONTACT: "/contact",
	PRODUCTINFO: "/productDetails",
	BUDGET: "/productBudget",
	TOPSELLING: "/productTopSelling",
	PROFILE: "/profile",
	SUBSCRIPTION: "/subscription",
	ALL_USER: "/allUser",
	ALL_PAYMENT: "/allPayment",
	ALL_ORDER: "/allOrder",
	FEED_DETAILS: "/feed/post",
	DASHBOARD: "/dashboard",
	ADMIN_FEED: "/feed/admin",
	FEED_LIST: "/feed/list",
	CARD_LIST: "/card/list",
	ADD_CARD: "/card",
	FAQ: "/FAQ",
	ADMIN_FAQ: "/admin-faq",
	CHANGE_PWD: "/changePwd",
	FORGOT_PWD: "/forgotPwd",
	RESET_PWD: "/resetPwd",
	PAYMENT_COMPLETE: "/paymentComplete",
};
export default RouteConstants;
