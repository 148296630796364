import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import validator from "validator";
import {
	CardElement,
	useStripe,
	useElements,
	// Elements,
	// CardNumberElement,
	// PaymentElement,
} from "@stripe/react-stripe-js";
import Box from "@mui/material/Box";
import { Button, Typography, Grid, TextField, Chip } from "@mui/material";
import Divider from "@mui/material/Divider";
import RemoveShoppingCartOutlinedIcon from "@mui/icons-material/RemoveShoppingCartOutlined";
// import StripeInput from "./StripeInput";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import RouteConstants from "../../constants/RouteConstant";
import Snackbars from "../Snackbar/Snackbar";
import { useSiteState, setSiteState } from "../../hookState/SiteState";
import Helper from "../../utillity/helper";
import { setCartState, useCartState } from "../../hookState/CartState";
import { Downgraded } from "@hookstate/core";
import CartCard from "../CartCard/CartCard";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
export const CheckoutForm = (props) => {
	console.log("props", props.type);
	const state = useSiteState();
	const cartState = useCartState();
	const [cartItems, setCartItems] = React.useState([]);
	const [cartLoader, setCartLoader] = React.useState(true);

	let navigate = useNavigate();
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = React.useState("");

	const [loading, setLoading] = React.useState(false);
	const [loading1, setLoading1] = React.useState(false);
	const [priceType, setPriceType] = React.useState({
		price: "",
		type: "",
	});

	const [firstNameErr, setFirstNameErr] = React.useState("");
	const [lastNameErr, setLastNameErr] = React.useState("");
	const [emailErr, setEmailErr] = React.useState("");
	const [passwordErr, setPasswordErr] = React.useState("");

	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});

	const validateEmail = (value) => {
		value = value.trim();
		if (validator.isEmail(value)) {
			return true;
		} else return false;
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		if (state.user.get() === "") {
			setError("");
			if (elements == null) {
				console.log("1");
				return;
			}
			console.log(data.get("firstName"));
			if (data.get("firstName") === "") {
				setFirstNameErr("First Name required");
				return;
			}
			if (data.get("lastName") === "") {
				setLastNameErr("Last Name required");
				return;
			}
			if (data.get("email") === "") {
				setEmailErr("Email required");
				return;
			} else {
				const email = data.get("email").trim();
				const flag = email !== "" ? validateEmail(email) : true;
				if (!flag) {
					const emlErr = flag ? "" : "Please enter valid Email";
					setEmailErr(emlErr);
					return;
				}
			}

			if (data.get("password") === "") {
				setPasswordErr("Password required");
				return;
			}
		}
		setLoading(true);
		let obj = {};

		state.user.get() === ""
			? (obj = {
					firstName: data.get("firstName"),
					lastName: data.get("lastName"),
					email: data.get("email"),
					password: data.get("password"),
					user_id: 0,
			  })
			: (obj = { user_id: state.user.id.get(), email: state.user.email.get() });
		console.log("object ", obj);

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardElement),
		});
		// if (priceType.type) {
		// 	console.log(props.cart);
		// 	return;
		// }

		window.dataLayer.push({
			event: "add_payment_info",
			ecommerce: {
				currency: "usd",
				value: props.amount,
				items: props.cart,
			},
		});

		if (!error) {
			try {
				const { id } = paymentMethod;
				console.log(id);
				console.log(props);

				const response = await ApiHelper.post(APIURLConstants.PAYMENT, {
					amount: props.amount,
					token: paymentMethod,
					id: paymentMethod.id,
					cart: props.cart,
					data: obj,
					type: priceType.type ? priceType.type : props.type,
				});

				console.log("paymentMethod", paymentMethod);

				console.log("response", response);

				window.dataLayer.push({
					event: "purchase",
					ecommerce: {
						transaction_id: id,
						currency: "usd",
						value: props.amount,
						items: props.cart,
					},
				});

				// console.log("Sucesss--------------------------", priceType.type);
				// console.log("Sucesss--------------------------", props.type);

				// console.log("Sucesss--------------------------", response);
				// console.log("Sucesss--------------------------", response.data);
				// console.log("Sucesss--------------------------", response.data.success);

				if (response.data.success) {
					console.log(
						"response client_secret",
						response.data.result.intent.client_secret
					);
					const result = await stripe.confirmCardPayment(
						response.data.result.intent.client_secret,
						{
							payment_method: {
								card: elements.getElement(CardElement),
							},
						}
					);
					console.log("result", result);
					if (result.error) {
						// Show error to your customer (e.g., authentication failed)
						setError(result.error.message);
						setLoading(false);

						return;
					}
					//  else if (result.paymentIntent.status === "succeeded") {
					// 	// Payment succeeded
					// 	alert("Payment successful!");
					// }
					console.log("Successful payment", response);
					setLoading(false);
					if (state && state.user.get() === "") {
						let data = response.data.result;
						let newData = data;
						if (response.data.result.plan.plan_id === 2) {
							delete data.plan;
							const plan = null;

							newData = {
								...data,
								plan,
							};
						}
						Helper.setStorage("userInfo", newData);
						Helper.removeStorage("cart");
						Helper.removeStorage("dataLayerCart");

						setCartState([]);
						setSiteState(data);
					} else {
						setCartState([]);
					}
					if (
						state &&
						state.user.get() !== "" &&
						(props.type === "unlimited" || priceType.type === "unlimited")
					) {
						setSnackBarProps({
							openSnack: true,
							variant: "success",
							message: "Thanks for the Purchase",
						});
						const user = Helper.getStorage("userInfo");
						Helper.removeStorage("userInfo");
						user.plan = response.data.result.plan;
						Helper.setStorage("userInfo", user);
						setCartState([]);
						// navigate(RouteConstants.TOPSELLING);

						// setSitePlanState(response.data.result.plan);
					}

					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "Thanks for the Purchase",
					});

					window.setTimeout(function() {
						// navigate(RouteConstants.MYACCOUNT);
						navigate(RouteConstants.PAYMENT_COMPLETE);
					}, 1000);
				} else {
					setLoading(false);
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message:
							response.data && response.data.message
								? response.data.message
								: "something wrong",
						// message: "something wrong",
					});
					return;
				}
			} catch (error) {
				setLoading(false);
				console.log("Error", error);
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: error,
				});
				setError(error);
			}
			//send token to backend here
		} else {
			setLoading(false);
			setError(error.message);
		}
	};
	useEffect(() => {
		if (state.user.get()) {
			getCart();
		} else {
			if (Helper.getStorage("cart")) {
				if (cartState.products.get().length > 0) {
					setCartItems(cartState.products.attach(Downgraded).get());
				} else {
					let cartArray = Helper.getStorage("cart");
					setCartItems(cartArray);
				}
				setCartLoader(false);
			}
		}
	}, []);
	const getCart = () => {
		let data = { user_id: state.user.id.get() };
		ApiHelper.post(APIURLConstants.GET_CART, data)
			.then((res) => {
				setCartItems(res.data.result.products);
				setCartState(res.data.result.products);
				setCartLoader(false);
			})
			.catch((error) => {
				setCartLoader(false);
				console.log(error);
			});
	};

	return (
		<>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					justifyContent: "center",
					// alignItems: "center",
					// m: "auto",
					p: 2,
				}}
			>
				{/* {loading && (
					<Box sx={{ height: "100%" }}>
						<CircularProgress />
					</Box>
				)} */}

				<Box
					sx={{
						width: {
							xs: "100%",
							md: props.type === "single" ? "30%" : "1%",
						},
						display: { xs: "none", md: "flex" },
						justifyContent: "center",
					}}
				>
					{props.type === "single" ? (
						<Box
							component="form"
							onSubmit={handleSubmit}
							sx={{
								display: "flex",
								flexDirection: "column",
								// alignContent: "center",
								overflowY: "scroll",
								// width: { xs: "100%", md: "50%" },
								// boxShadow: "0px 15px 55px 1px #ecf2ff",
								boxShadow: "0px 15px 5px 1px #ecf2ff",

								borderRadius: "12px",
								padding: 2,
								height: {
									xs: 250,
									md: "calc(100vh - 156px)",
								},
							}}
						>
							<Button
								variant="text"
								onClick={() => {
									navigate(RouteConstants.CART);
								}}
								sx={{
									color: "#000",
									textTransform: "capitalize",
									alignContent: "start",
									justifyContent: "start",
									fontSize: 18,
									fontWeight: 600,
								}}
							>
								<KeyboardBackspaceIcon /> Back to Cart
							</Button>

							{cartLoader ? (
								"Loading ..."
							) : cartItems.length > 0 ? (
								cartItems.map((cartItem, index) => {
									return (
										<CartCard
											key={index}
											cartItem={cartItem}
											showDelete={false}
										/>
									);
								})
							) : (
								<Typography
									fontSize={16}
									color="initial"
									width={{ xs: "100%", md: "340px" }}
									paddingTop={4}
									// marginLeft={{ xs: 5, md: 0 }}
									sx={{ display: "flex" }}
								>
									<RemoveShoppingCartOutlinedIcon /> Cart is Empty
								</Typography>
							)}
						</Box>
					) : null}
				</Box>

				<Box
					component="form"
					onSubmit={handleSubmit}
					sx={{
						// mt: 3,

						width: { xs: "100%", md: "50%" },
						boxShadow: "0px 15px 55px 1px #E6E6E6",
						borderRadius: "12px",
						padding: 2,
						height: {
							md: state.user.get() === "" ? "calc(100vh - 320px)" : "250px",
						},
						my: "auto",
					}}
				>
					<Typography
						fontWeight={600}
						fontSize={26}
						color={"#000"}
						align="center"
						alignContent={"center"}
					>
						Start your business journey today!
					</Typography>
					{error && (
						<Typography
							fontSize={15}
							color="initial"
							sx={{ color: "#FF0000", paddingBottom: 1 }}
						>
							{error}
						</Typography>
					)}
					<Box sx={{ pb: 2 }}>
						{state.user.get() === "" && (
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										autoComplete="given-name"
										name="firstName"
										required
										fullWidth
										id="firstName"
										label="First Name"
										autoFocus
										color="secondary"
										error={firstNameErr.length > 0 ? true : false}
										helperText={firstNameErr.length > 0 ? firstNameErr : ""}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										required
										fullWidth
										id="lastName"
										label="Last Name"
										name="lastName"
										autoComplete="family-name"
										color="secondary"
										error={lastNameErr.length > 0 ? true : false}
										helperText={lastNameErr.length > 0 ? lastNameErr : ""}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										color="secondary"
										error={emailErr.length > 0 ? true : false}
										helperText={emailErr.length > 0 ? emailErr : ""}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										required
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										autoComplete="new-password"
										color="secondary"
										error={passwordErr.length > 0 ? true : false}
										helperText={passwordErr.length > 0 ? passwordErr : ""}
									/>
								</Grid>
							</Grid>
						)}
					</Box>
					<Box
						sx={{
							bordeRadius: "4px",
							border: "1px solid #0072B1	",
							py: "16.5px",
							px: "14px",
						}}
					>
						<CardElement options={{ hidePostalCode: true }} />
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							mx: "auto",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								width: props.type !== "unlimited" ? "50%" : "100%",
								mx: "auto",
							}}
						>
							<Button
								onClick={() =>
									setPriceType({
										price: "",
										type: "",
									})
								}
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									color: "primary.main",
									bgcolor: "tertiary.main",
									width: "60%",
									mx: "auto",
								}}
								color="inherit"
							>
								{loading ? (
									<CircularProgress />
								) : (
									"Pay " + "($" + props.amount + ")"
								)}
							</Button>
						</Box>
						{props.type !== "unlimited" && (
							<Divider>
								<Chip label="OR" />
							</Divider>
						)}

						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								width: props.type !== "unlimited" ? "50%" : "0%",
								mx: "auto",
							}}
						>
							{props.type !== "unlimited" && (
								<>
									<Button
										onClick={() =>
											setPriceType({
												price: 1,
												type: "unlimited",
											})
										}
										type="submit"
										// onSubmit={handleSubmit}
										fullWidth
										variant="contained"
										sx={{
											mt: 3,
											mb: 2,
											color: "primary.main",
											bgcolor: "tertiary.main",
											width: "80%",
											mx: "auto",
										}}
										color="inherit"
									>
										{loading ? (
											<CircularProgress />
										) : (
											"Pay" + "($" + 1 + ")  FOR 50 REPORTS!!"
										)}
									</Button>
								</>
							)}
						</Box>
					</Box>
				</Box>

				{/* <form onSubmit={handleSubmit}>
				<button type="submit" disabled={!stripe || !elements}>
					Pay
				</button>
				muiBu
			</form> */}
			</Box>
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</>
	);
};
