import React from "react";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/logo.png";
import { useSiteState, logout } from "../../hookState/SiteState";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import RssFeedIcon from "@mui/icons-material/RssFeed";
// import SearchIcon from "@mui/icons-material/ScreenSearchDesktopOutlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { useEffect, useState } from "react";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import { useCartState, setCartState } from "../../hookState/CartState";
import Helper from "../../utillity/helper";
import { Downgraded, none } from "@hookstate/core";
import { setProfileState } from "../../hookState/ProfileState";
import { useTranslation } from "react-i18next";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";
import { Navbar } from "../Navbar/Navbar";
const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		right: -3,
		top: 13,
		// border: `2px solid ${theme.palette.background.paper}`,
		// background: theme.palette.tertiary.main,
		padding: "0 4px",
	},
}));
const pages = ["Pricing", "Blog"];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];
const useStyles = makeStyles({
	root: {
		boxShadow: "none",
		backgroundColor: "red",
	},
	logo: {
		height: 35,
	},
	mobileLogo: {
		alignContent: "flex-start",
		// height: 30,
		height: 20,
	},
	headerTilte: {
		paddingLeft: 11,
		// paddingRight: 11,
	},
	setScale: {
		transform: "scale(1)",
		fontSize: "1rem",
		stroke: "red",
		strokeWidth: 2,
		marginTop: "-3px",
	},
	initialSection: {
		flex: "0 0 auto",
		padding: 12,
		textAlign: "center",
		marginRight: 0,
	},
	nopointer: {
		mx: 1,
		textDecoration: "none",
	},
});
const Header = () => {
	const state = useSiteState();
	const { t, i18n } = useTranslation();
	let navigate = useNavigate();
	const cartState = useCartState();

	const [anchorElNav, setAnchorElNav] = React.useState(false);
	const [sideBar, setSideBar] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	// const [anchorElUser, setAnchorElUser] = React.useState(null);
	const classes = useStyles();
	const [cartCount, setCartCount] = useState(0);
	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	// const handleOpenUserMenu = (event) => {
	// 	setAnchorElUser(event.currentTarget);
	// };
	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleLogoutClose = () => {
		Helper.removeStorage("dataLayerCart");

		setProfileState(false);
		logout();
		setCartState([]);
		navigate(RouteConstants.LANDING);
		setAnchorEl(null);
	};

	// const handleCloseNavMenu = () => {
	// 	setAnchorElNav(null);
	// };
	const handleNavMenu = (open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setAnchorElNav(open);
	};
	const handleSideBarMenu = (open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setSideBar(open);
	};

	const handleRedirect = (val) => {
		setProfileState(false);
		if (val === "login") {
			navigate(RouteConstants.LOGIN);
		} else {
			logout();
			setCartState([]);
			navigate(RouteConstants.LANDING);
		}
	};

	const handleProfileRedirect = (role) => {
		setProfileState(true);
		console.log(state.get());
		if (state.get().role === "admin") {
			navigate(RouteConstants.DASHBOARD);
		} else {
			navigate(RouteConstants.PROFILE);
		}

		// navigate(RouteConstants.PROFILE);
	};
	const handleRedirectLink = (val) => {
		setProfileState(false);
		// if (val === "myaccount") {
		// 	navigate(RouteConstants.MYACCOUNT);
		// }
		// if (val === "price") {
		// 	navigate(RouteConstants.PRICE);
		// }
		// if (val === "price") {
		// 	navigate(RouteConstants.PRICE);
		// }
	};

	useEffect(() => {
		if (state.user.get()) {
			// getCart();
			setCartCount(cartState.products.attach(Downgraded).get().length);
		} else {
			setCartCount(cartState.products.attach(Downgraded).get().length);
			if (Helper.getStorage("cart")) {
				if (cartState.products.get().length > 0) {
					setCartCount(cartState.products.attach(Downgraded).get().length);
				} else {
					let cartArray = Helper.getStorage("cart");
					setCartCount(cartArray && cartArray.length);
				}
			}
		}
	}, [cartState && cartState.products]);
	// useEffect(() => {
	// 	if (state.user.get()) {
	// 		getCart();
	// 	}
	// }, [state && state.user]);
	const logoRedirect = () => {
		setProfileState(false);
		navigate(RouteConstants.LANDING);
	};
	const handleCallback = () => {
		setSideBar(false);
	};
	const list = () => (
		<>
			<Box
				sx={{ width: 250 }}
				role="presentation"
				onClick={handleNavMenu(false)}
				onKeyDown={handleNavMenu(false)}
			>
				<Box sx={{ height: 70, p: 1 }}>
					<Box
						component="img"
						sx={{
							// mx: "auto",
							// marginTop: 2,
							m: 2,

							cursor: "pointer",
						}}
						className={classes.logo}
						alt="logo."
						src={Logo}
						onClick={() => logoRedirect()}
					/>
				</Box>
				<Divider />
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyItems: "center",
						justifyContent: "center",
						p: "8px 16px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							mx: 2,
							fontWeight: 500,
							fontSize: 14,
							height: "35px",
							py: 3,
						}}
					>
						<SearchIcon sx={{ color: "tertiary.main", mx: 1 }} />
						<Link
							color="#000000"
							to={RouteConstants.TOPSELLING}
							className={classes.nopointer}
							onClick={() => setProfileState(false)}
						>
							<Typography
								fontSize={14}
								fontWeight={400}
								width={"180px"}
								// fontFamily={"Roboto"}
								color="#000000"
							>
								Products
							</Typography>
						</Link>
					</Box>
					{state.user.get() !== "" && (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								// mx: 2,
								fontWeight: 500,
								fontSize: 14,
								height: "35px",
								py: 3,
							}}
						>
							<BusinessCenterIcon sx={{ color: "tertiary.main", mx: 1 }} />
							<Link
								color="black"
								to={RouteConstants.MYACCOUNT}
								className={classes.nopointer}
								onClick={() => setProfileState(false)}
							>
								<Typography
									width={"180px"}
									fontSize={14}
									fontWeight={400}
									// fontFamily={"Roboto"}
									color="#000000"
								>
									My Account
								</Typography>
							</Link>
						</Box>
					)}
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							mx: 2,
							fontWeight: 500,
							height: "35px",
							py: 3,
						}}
					>
						<LocalAtmIcon sx={{ color: "tertiary.main", mx: 1 }} />
						<Link
							color="black"
							to={RouteConstants.PRICE}
							className={classes.nopointer}
							onClick={() => setProfileState(false)}
						>
							<Typography
								fontSize={14}
								fontWeight={400}
								width={"180px"}
								// fontFamily={"Roboto"}
								color="#000000"
							>
								Pricing
							</Typography>
						</Link>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							mx: 2,
							fontWeight: 500,
							fontSize: 15,

							height: "35px",
							py: 3,
						}}
					>
						<RssFeedIcon sx={{ color: "tertiary.main", mx: 1 }} />
						<Link
							color="#000000"
							to={RouteConstants.FEED}
							className={classes.nopointer}
							onClick={() => setProfileState(false)}
						>
							<Typography
								color="initial"
								fontSize={14}
								fontWeight={400}
								width={"180px"}
								// fontFamily={"Roboto"}
								color="#000000"
							>
								Feed
							</Typography>
						</Link>
					</Box>
				</Box>
			</Box>
		</>
	);
	const profileList = () => (
		<>
			<Box
				sx={{ width: 200 }}
				role="presentation"
				onClick={handleNavMenu(false)}
				onKeyDown={handleNavMenu(false)}
			>
				<Box
					component="img"
					sx={{
						// mx: "auto",
						// marginTop: 2,
						m: 2,
						height: 25,

						cursor: "pointer",
					}}
					// className={classes.logo}
					alt="logo."
					src={Logo}
					onClick={() => logoRedirect()}
				/>

				<Divider />

				<Navbar handleCallback={handleCallback} />
			</Box>
		</>
	);
	return (
		<AppBar position="static">
			<Toolbar disableGutters sx={{ px: { xs: 0, md: 8 } }}>
				<Box
					component="img"
					sx={{ mr: 2, display: { xs: "none", md: "flex" }, cursor: "pointer" }}
					className={classes.logo}
					alt="logo."
					src={Logo}
					onClick={() => logoRedirect()}
				/>

				<Box sx={{ display: { xs: "flex", md: "none" } }}>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleNavMenu(true)}
						color="inherit"
					>
						<MenuIcon />
					</IconButton>
				</Box>

				<Box
					sx={{ display: { xs: "flex", md: "none" } }}
					component="img"
					className={classes.mobileLogo}
					alt="logo."
					src={Logo}
					onClick={() => logoRedirect()}
				/>
				{/* For web */}
				<Box sx={{ flexGrow: 1, display: "flex" }}>
					<Box
						sx={{
							display: { xs: "none", md: "flex" },
							justifyContent: "center",
							alignItems: "center",
							mx: 2,
							fontWeight: 500,
						}}
					>
						<LocalAtmIcon sx={{ color: "tertiary.main", mx: 0.5 }} />
						<Link
							color="black"
							to={RouteConstants.PRICE}
							className={classes.nopointer}
							onClick={() => setProfileState(false)}
						>
							<Typography color="initial" fontSize={14}>
								Pricing
							</Typography>
						</Link>
					</Box>
					<Box
						sx={{
							display: { xs: "none", md: "flex" },
							justifyContent: "center",
							alignItems: "center",
							mx: 2,
							fontWeight: 500,
							fontSize: 14,
						}}
					>
						<RssFeedIcon sx={{ color: "tertiary.main", mx: 0.5 }} />
						<Link
							color="#000000"
							to={RouteConstants.FEED}
							className={classes.nopointer}
							onClick={() => setProfileState(false)}
						>
							<Typography color="initial" fontSize={14}>
								Feed
							</Typography>
						</Link>
					</Box>
					<Box
						sx={{
							display: { xs: "none", md: "flex" },
							justifyContent: "center",
							alignItems: "center",
							mx: 2,
							fontWeight: 500,
						}}
					>
						<SearchIcon sx={{ color: "tertiary.main", mx: 0.5 }} />
						<Link
							color="black"
							to={RouteConstants.TOPSELLING}
							className={classes.nopointer}
							onClick={() => setProfileState(false)}
						>
							<Typography color="initial" fontSize={14}>
								Products
							</Typography>
						</Link>
					</Box>
					{state.user.get() !== "" && (
						<Box
							sx={{
								display: { xs: "none", md: "flex" },
								justifyContent: "center",
								alignItems: "center",
								mx: 2,
								fontWeight: 500,
								fontSize: 14,
							}}
						>
							<BusinessCenterIcon sx={{ color: "tertiary.main", mx: 0.5 }} />
							<Link
								color="black"
								to={RouteConstants.MYACCOUNT}
								className={classes.nopointer}
								onClick={() => setProfileState(false)}
							>
								<Typography color="initial" fontSize={14}>
									My Account
								</Typography>
							</Link>
						</Box>
					)}
				</Box>
				<Box sx={{ flexGrow: 0, display: "flex" }}>
					{state.user.get() !== null && state.plan.get() === null && (
						<IconButton
							aria-label="cart"
							sx={{ mx: { xs: 1, md: 2 } }}
							onClick={() => navigate(RouteConstants.CART)}
						>
							<StyledBadge
								badgeContent={cartCount}
								color="secondary"
								onClick={() => setProfileState(false)}
							>
								<ShoppingCartIcon
									sx={{
										color: "tertiary.main",
										fontSize: { xs: "1.4rem", md: "1.5rem" },
									}}
								/>
							</StyledBadge>
						</IconButton>
					)}
					{!state.user.get() && (
						<IconButton
							aria-label="cart"
							sx={{ mx: { xs: 0, md: 2 } }}
							onClick={() => navigate(RouteConstants.CART)}
						>
							<StyledBadge badgeContent={cartCount} color="secondary">
								<ShoppingCartIcon
									sx={{
										color: "tertiary.main",
										fontSize: { xs: "1.4rem", md: "1.5rem" },
										// margin: { xs: "-2px" },
									}}
								/>
							</StyledBadge>
						</IconButton>
					)}

					{state.user.get() === "" ? (
						<Button
							color="secondary"
							className={classes.nopointer}
							sx={{ mx: { xs: "-4px", md: 2 } }}
							onClick={() => handleRedirect("login")}
						>
							<Typography
								color="initial"
								textTransform={"capitalize"}
								fontSize={{ xs: 12, md: 14 }}
							>
								Login
							</Typography>
						</Button>
					) : (
						<Button
							color="secondary"
							className={classes.nopointer}
							sx={{
								display: { xs: "none", md: "none" },
								visibility: "hidden",
								mx: { xs: "-4px", md: 2 },
							}}
							onClick={() => handleRedirect("login")}
						>
							<Typography
								color="initial"
								textTransform={"capitalize"}
								fontSize={{ xs: 12, md: 14 }}
							>
								Login
							</Typography>
						</Button>
					)}

					{state.user.get() !== "" ? (
						<>
							<Avatar
								sx={{
									display: { xs: "none", md: "flex" },
									bgcolor: "tertiary.main",
									height: { xs: 25, md: 40 },
									width: { xs: 25, md: 40 },
									m: "auto",
									mx: "0.8em",
								}}
								onClick={() => {
									handleProfileRedirect(state.role.get());
								}}
							>
								{state.user &&
									state.user
										.get()
										.firstName.charAt(0)
										.toUpperCase()}
							</Avatar>
							<Avatar
								sx={{
									display: { xs: "flex", md: "none" },
									bgcolor: "tertiary.main",
									height: { xs: 25, md: 40 },
									width: { xs: 25, md: 40 },
									m: "auto",
									mx: "0.8em",
								}}
								onClick={() => setSideBar(true)}
							>
								{state.user &&
									state.user
										.get()
										.firstName.charAt(0)
										.toUpperCase()}
							</Avatar>

							{/* <Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorEl)}
								onClose={handleClose}
								sx={{ display: { xs: "none", md: "flex" } }}
							>
								<MenuItem
									onClick={() => {
										handleProfileRedirect(state.role.get());
									}}
									sx={{ display: { xs: "none", md: "block" } }}
								>
									Profile
								</MenuItem>
								<MenuItem onClick={handleLogoutClose}>Logout</MenuItem>
							</Menu> */}
						</>
					) : (
						<Button
							color="inherit"
							sx={{
								alignItems: "center",
								justifyContent: "center",
								my: "auto",
								color: "primary.main",
								bgcolor: "tertiary.main",
								px: { xs: 0, md: 2 },
								// mx: { xs: "-5px" },
								borderRadius: "4px",
								height: { xs: "23px", md: "32px" },
								minWidth: "46px",
								// width: { xs: "20px" },
							}}
							onClick={() => navigate(RouteConstants.SIGNUP)}
						>
							<Typography
								textTransform={"capitalize"}
								fontSize={{ xs: 10, md: 14 }}
							>
								Sign Up
							</Typography>
						</Button>
					)}
				</Box>
			</Toolbar>
			<SwipeableDrawer
				open={anchorElNav}
				onClose={handleNavMenu(false)}
				onOpen={handleNavMenu(true)}
			>
				{list()}
			</SwipeableDrawer>
			<SwipeableDrawer
				anchor={"right"}
				open={sideBar}
				onClose={handleSideBarMenu(false)}
				onOpen={handleSideBarMenu(true)}
			>
				{profileList()}
			</SwipeableDrawer>
		</AppBar>
	);
};
export default Header;
