import React, { useEffect, useState } from "react";

import { Box, TextField, Button, Typography } from "@mui/material";
import PaymentCompleteImage from "../../assets/paymate.png";
import CircularProgress from "@mui/material/CircularProgress";
import RouteConstants from "../../constants/RouteConstant";
import { useNavigate } from "react-router-dom";

export const PaymentComplete = () => {
	const [loading, setLoading] = React.useState(true);
	const navigate = useNavigate();
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});

	useEffect(() => {
		setLoading(false);
	}, []);
	return (
		<Box sx={{ background: "aliceblue", height: "100%" }}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						p: 2,
						width: "100%",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", md: "row" },
							justifyContent: "center",
							alignItems: "center",
							alignContent: "center",
							alignSelf: "center",
							alignTracks: "center",
							height: "calc(100vh - 250px)",
						}}
					>
						<Box
							component="img"
							sx={{
								height: { xs: 80, md: 150 },
								marginTop: 1,
								cursor: "pointer",
								marginLeft: "10px",
								marginRight: "10px",
							}}
							alt="logo"
							src={PaymentCompleteImage}
						/>
						<Box
							sx={{
								display: { xs: "none", md: "flex" },
								flexDirection: "column",
							}}
						>
							<Typography
								component="h1"
								variant={"h3"}
								alignContent={"flex-start"}
								fontWeight={600}
								fontSize={23}
							>
								{"Thank You for Your Purchase!"}
							</Typography>
							<Typography
								component="h1"
								variant="h5"
								alignContent={"flex-start"}
								// fontWeight={600}
								fontSize={22}
							>
								{"We wish you the very best in your ecommerce journey."}
							</Typography>
							<Typography
								component="h1"
								variant="h5"
								alignContent={"flex-start"}
								// fontWeight={600}
								fontSize={22}
							>
								{"Your payment has been successfully processed."}
							</Typography>
							<Typography
								component="h1"
								variant="h5"
								alignContent={"flex-start"}
								// fontWeight={600}
								fontSize={22}
							>
								{
									"You can view and manage your purchase at any time by visiting the"
								}
								<Button
									variant="text"
									onClick={() => navigate(RouteConstants.MYACCOUNT)}
								>
									<Typography
										component="h1"
										variant="h5"
										alignContent={"flex-start"}
										fontWeight={600}
										fontSize={22}
										color={"#1976d2"}
									>
										{"My Account"}
									</Typography>
								</Button>
								{"page."}
							</Typography>
						</Box>
						<Box
							sx={{
								display: { xs: "flex", md: "none", flexWrap: "wrap" },
								flexDirection: "column",
								gap: 2,
								justifyContent: "center",
								alignItems: "center",
								alignSelf: "center",
							}}
						>
							<Typography
								component="h1"
								variant={"h6"}
								alignContent={"flex-start"}
								fontWeight={600}
							>
								{"Thank You for Your Purchase!"}
							</Typography>
							<Typography
								component="h1"
								variant="h5"
								alignContent={"flex-start"}
								// fontWeight={600}
								fontSize={19}
								textAlign={"center"}
							>
								{"We wish you the very best in your ecommerce journey."}
							</Typography>
							<Typography
								component="h1"
								variant="h5"
								alignContent={"flex-start"}
								// fontWeight={600}
								fontSize={19}
								textAlign={"center"}
							>
								{"Your payment has been successfully processed."}
							</Typography>
							<Typography
								component="h1"
								variant="h5"
								alignContent={"flex-start"}
								// fontWeight={600}
								fontSize={19}
								textAlign={"center"}
							>
								{
									"You can view and manage your purchase at any time by visiting the"
								}
								<Button
									variant="text"
									onClick={() => navigate(RouteConstants.MYACCOUNT)}
								>
									<Typography
										component="h1"
										variant="h5"
										alignContent={"flex-start"}
										fontWeight={600}
										fontSize={19}
										textAlign={"center"}
										color={"#1976d2"}
									>
										{"My Account"}
									</Typography>
								</Button>
								{"page."}
							</Typography>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
};
