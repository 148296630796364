import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

// import AppleIcon from "@mui/icons-material/Apple";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GoogleIcon from "@mui/icons-material/Google";
// import InstagramIcon from "@mui/icons-material/Instagram";
import {
	TextField,
	Button,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Typography,
	Divider,
	Grid,
} from "@mui/material";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import Helper from "../../utillity/helper";
import { setSiteState } from "../../hookState/SiteState";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant";
import {
	setSocialLoginState,
	changeSocialState,
} from "../../hookState/SocialLoginState";
import validator from "validator";
import GoogleIcon from "../../assets/google.svg";
import FacebookIcon from "../../assets/fb.svg";
import InstagramIcon from "../../assets/insta.svg";
import AmazonIcon from "../../assets/amazon.svg";
import { setCartState } from "../../hookState/CartState";

// import GIcon from "../../assets/google.svg";
export const LoginDetails = () => {
	const useStyles = makeStyles((theme) => ({
		margin: {
			margin: theme.spacing(1),
		},
		socialButton: {
			margin: theme.spacing(1),
		},

		textField: {
			width: 400,
			[theme.breakpoints.down("md")]: {
				width: 320,
			},
			display: "flex",
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&:hover fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&.Mui-focused fieldset": {
					borderColor: theme.palette.secondary.main,
				},
			},
			"&:hover .MuiInputLabel-root": {
				color: theme.palette.secondary.main,
			},
			"& .Mui-focused.MuiInputLabel-root": {
				color: theme.palette.secondary.main,
			},
		},
		outlinedInput: {
			"&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
				color: theme.palette.secondary.main,
			},
			"&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
				color: theme.palette.secondary.main,
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					width: "200px",
					height: "70px",
				},
			},
		},
	}));
	const classes = useStyles();

	let navigate = useNavigate();
	const [emailErr, setEmailErr] = React.useState("");
	const [passwordErr, setPasswordErr] = React.useState("");

	const getCart = (id) => {
		let data = { user_id: id };
		ApiHelper.post(APIURLConstants.GET_CART, data)
			.then((res) => {
				// setCartCount(res.data.result.products.length);
				setCartState(res.data.result.products);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		if (data.get("email") === "") {
			setEmailErr("Email required");
			return;
		} else {
			const email = data.get("email").trim();
			const flag = email !== "" ? validateEmail(email) : true;
			if (!flag) {
				const emlErr = flag ? "" : "Please enter valid Email";
				setEmailErr(emlErr);
				return;
			}
		}

		if (data.get("password") === "") {
			setPasswordErr("Password required");
			return;
		}
		let obj = {};
		ApiHelper.post(APIURLConstants.POST_LOGIN, data)
			.then((res) => {
				if (res.data) {
					if (res.data.success) {
						Helper.setStorage("userInfo", res.data.result);

						window.dataLayer.push({
							event: "login",
							user_id: res.data.result.user.id,
						});
						obj = {
							user: res.data.result.user,
							token: res.data.token,
							plan: res.data.result.plan,
							role: res.data.result.role,
						};
						setSiteState(obj);
						const cart = Helper.getStorage("cart");
						let cartAsin = [];
						if (cart) {
							let cartCopy = [...cart];
							cartAsin = cartCopy.map((key) => key.asin);
							const data = {
								cartAsin: cartAsin,
								user_id: res.data.result.user.id,
							};
							if (cartCopy.length > 0) {
								ApiHelper.post(APIURLConstants.add_products_cart, data)
									.then((response) => {
										if (res.data.success) {
											Helper.removeStorage("cart");
											Helper.removeStorage("dataLayerCart");
										}
									})
									.catch((error) => {
										console.log(error);
									});
							}
							getCart(res.data.result.user.id);
						}

						navigate(RouteConstants.TOPSELLING);
					} else {
						if (res.data.password) {
							setPasswordErr(res.data.message);
							return;
						} else {
							setEmailErr(res.data.message);
							return;
						}
					}
				} else {
					// disableButton.set(false);
					// setSnackBarProps({
					// 	...snackBarProp,
					// 	openSnack: true,
					// 	variant: "error",
					// 	message: "This event name already exists",
					// });
				}
				// loading.set(false);
			})
			.catch((error) => {
				console.log(error);
			});
		console.log({
			email: data.get("email"),
			password: data.get("password"),
		});
	};

	const loginButton = (provider) => {
		changeSocialState();
		setSocialLoginState(provider);
		// Helper.removeStorage("loginOption");
		Helper.setStorage("loginOption", provider);
		ApiHelper.get(`${APIURLConstants.GOOGLE + provider}`)
			.then((res) => {
				console.log(res.data);
				window.location = res.data;

				// window.open(res.data, "_blank").focus();

				// window.open(res.data);
				// if (res.data) {
				// 	Helper.setStorage("userInfo", res.data.result);
				// 	setSiteState(res.data.result);
				// 	navigate(RouteConstants.HOME);
				// }
				// // loading.set(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const validateEmail = (value) => {
		value = value.trim();
		if (validator.isEmail(value)) {
			return true;
		} else return false;
	};

	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexWrap: "wrap",

					flexDirection: { xs: "column", md: "row" },
					p: { xs: 0, md: 4 },
				}}
			>
				<Box
					sx={{
						width: { xs: "100%", md: "50%" },
						// height: "100vh",
						// height: "calc(100vh - 70px)",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						alignContent: "center",
					}}
				>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: { xs: "center", md: "flex-start" },
						}}
					>
						<Typography
							component="h1"
							variant="h5"
							alignContent={"flex-start"}
							fontWeight={600}
						>
							Login
						</Typography>
						<Box
							component="form"
							noValidate
							onSubmit={handleSubmit}
							sx={{ mt: 3, width: { xs: 290, md: 400 } }}
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										color="secondary"
										error={emailErr.length > 0 ? true : false}
										helperText={emailErr.length > 0 ? emailErr : ""}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										required
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										autoComplete="new-password"
										color="secondary"
										error={passwordErr.length > 0 ? true : false}
										helperText={passwordErr.length > 0 ? passwordErr : ""}
									/>
								</Grid>
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									color: "primary.main",
									bgcolor: "tertiary.main",
								}}
								color="inherit"
							>
								Login
							</Button>
						</Box>

						<Box
							className={classes.margin}
							sx={{
								width: { xs: 290, md: 410 },
								display: "flex",
								// flexWrap: "wrap",
								flexDirection: "row",
								justifyContent: "space-between",
							}}
						>
							<FormGroup sx={{ justifyContent: "flex-start" }}>
								<FormControlLabel
									control={<Checkbox defaultChecked color="secondary" />}
									label={
										<Typography color="secondary" variant="body2">
											Remember Me
										</Typography>
									}
								/>
							</FormGroup>
							<Button
								variant="text"
								color="secondary"
								sx={{ justifyContent: "flex-end" }}
								onClick={() => {
									navigate(RouteConstants.FORGOT_PWD);
								}}
							>
								<Typography
									color="secondary"
									textTransform="capitalize"
									variant="body2"
								>
									Forgot Password?
								</Typography>
							</Button>
						</Box>

						<Box
							className={classes.margin}
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								width: { xs: 290, md: 410 },
								color: "secondary.main",
							}}
						>
							<Divider spacing={2}>
								<Typography
									color="secondary"
									textTransform="capitalize"
									variant="subtitle1"
								>
									Or Sign In With
								</Typography>
							</Divider>
							<Box
								className={classes.margin}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
								}}
							>
								<Box
									component="img"
									sx={{
										height: 50,
										marginTop: 1,
										cursor: "pointer",
										marginLeft: "10px",
										marginRight: "10px",
									}}
									alt="logo"
									src={GoogleIcon}
									onClick={() => loginButton("google")}
								/>

								{/* <Box
									component="img"
									sx={{
										height: 50,
										marginTop: 1,
										cursor: "pointer",
										marginLeft: "10px",
										marginRight: "10px",
									}}
									alt="logo"
									src={FacebookIcon}
									onClick={() => loginButton("facebook")}
								/> 

								<Box
									component="img"
									sx={{
										height: 50,
										marginTop: 1,
										cursor: "pointer",
										marginLeft: "10px",
										marginRight: "10px",
									}}
									alt="logo"
									src={InstagramIcon}
									onClick={() => loginButton("instagram")}
								/>

								<Box
									component="img"
									sx={{
										height: 50,
										marginTop: 1,
										cursor: "pointer",
										marginLeft: "10px",
										marginRight: "10px",
									}}
									alt="logo."
									src={AmazonIcon}
									onClick={() => loginButton("amazon")}
								/>*/}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
