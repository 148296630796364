import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import Typography from "@mui/material/Typography";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import Helper from "../../utillity/helper";
import ProductCard from "../../components/Product/ProductCard";
import Snackbars from "../../components/Snackbar/Snackbar";
import { setCartState } from "../../hookState/CartState";
import Category from "../../components/Category/Category";
import CircularProgress from "@mui/material/CircularProgress";
import { categories, premiumCount } from "../../constants/Constant";
import { useSiteState } from "../../hookState/SiteState";
import { usePlanState } from "../../hookState/PlanState";
import { Downgraded } from "@hookstate/core";
import SearchIcon from "@mui/icons-material/Search";

export const TopSelling = () => {
	const state = useSiteState();
	const planState = usePlanState();

	// console.log("state", state);
	const [perPage] = useState(9);
	const [currentPage] = useState(1);
	const [products, setProducts] = useState([]);
	const [cartCount, setCartCount] = useState(0);

	// const [search] = useState("");
	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const [categoryId, setCategoryId] = useState("all");
	const [loading, setLoading] = useState(false);
	// const [value, setValue] = React.useState(categories[0]);
	const [cartAdded, setCartAdded] = useState([]);
	const [myProductCount, setMyProductCount] = useState(0);

	let [cart, setCart] = useState([]);
	const getCart = (id) => {
		let data = { user_id: id };
		ApiHelper.post(APIURLConstants.GET_CART, data)
			.then((res) => {
				// setCartCount(res.data.result.products.length);
				console.log(
					"Cat Length---------------------------",
					res.data.result.products.length
				);
				setCartState(res.data.result.products);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const addCartItem = (productInfo) => {
		const dataLayerCart = Helper.getStorage("dataLayerCart");
		console.log(dataLayerCart);
		if (
			state.plan.get() !== null &&
			state.plan !== "" &&
			state.plan !== undefined
		) {
			if (myProductCount >= premiumCount) {
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message:
						"You have reached your limit of 50 reports. This will renew on your next payment date. You can purchase individual reports in the meantime",
				});
				return;
			}
			ApiHelper.post(APIURLConstants.add_products_myaccount, productInfo)
				.then((res) => {
					if (res.data) {
						if (res.data.success) {
							setSnackBarProps({
								openSnack: true,
								variant: "success",
								message: "Product Added to Account",
							});
							getMyProducts();

							if (dataLayerCart === null) {
								let dataLayerCartCopy = [];
								Helper.setStorage("dataLayerCart", productInfo);
								window.dataLayer.push({
									event: "add_to_cart",
									ecommerce: {
										currency: "",
										value: "",
										items: productInfo,
									},
								});
							} else {
								console.log("dataLayerCart", dataLayerCart);
								let dataLayerCartCopy = [dataLayerCart];

								dataLayerCartCopy.push(productInfo);
								Helper.setStorage("dataLayerCart", dataLayerCartCopy);

								window.dataLayer.push({
									event: "add_to_cart",
									ecommerce: {
										currency: "",
										value: "",
										items: dataLayerCartCopy,
									},
								});
							}
							getProducts();
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			ApiHelper.post(APIURLConstants.ADD_TO_CART, productInfo)
				.then((res) => {
					if (res.data) {
						if (res.data.success) {
							setSnackBarProps({
								openSnack: true,
								variant: "success",
								message: "Product Added in Card",
							});
							getProducts();
							getCart(productInfo.user_id);
							if (dataLayerCart === null) {
								Helper.setStorage("dataLayerCart", productInfo);
								window.dataLayer.push({
									event: "add_to_cart",
									ecommerce: {
										currency: "",
										value: "",
										items: productInfo,
									},
								});
							} else {
								let dataLayerCartCopy = [dataLayerCart];
								dataLayerCartCopy.push(productInfo);
								Helper.setStorage("dataLayerCart", dataLayerCartCopy);
								window.dataLayer.push({
									event: "add_to_cart",
									ecommerce: {
										currency: "",
										value: "",
										items: dataLayerCartCopy,
									},
								});
							}
						} else {
							setSnackBarProps({
								openSnack: true,
								variant: "success",
								message: "Product Already in Cart",
							});
							return;
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const addItem = (item) => {
		const cart = Helper.getStorage("cart");

		if (cart) {
			let cartCopy = [...cart];
			let { asin } = item;
			let existingItem = cartCopy.find((cartItem) => cartItem.asin == asin);
			if (existingItem) {
				setSnackBarProps({
					openSnack: true,
					variant: "success",
					message: "Product Already in Cart",
				});
				return;
			} else {
				cartCopy.push(item);
			}
			setCart(cartCopy);
			setCartState(cartCopy);
			Helper.setStorage("cart", cartCopy);
			window.dataLayer.push({
				event: "add_to_cart",
				ecommerce: {
					currency: "",
					value: "",
					items: cartCopy,
				},
			});
			setSnackBarProps({
				openSnack: true,
				variant: "success",
				message: "Product Added in Card",
			});
		} else {
			let cart = [];
			let cartCopy = [...cart];
			let { asin } = item;
			let existingItem = cartCopy.find((cartItem) => cartItem.asin == asin);

			if (existingItem) {
				setSnackBarProps({
					openSnack: true,
					variant: "success",
					message: "Product Already in Cart",
				});
				return;
			} else {
				cartCopy.push(item);
			}
			setCart(cartCopy);
			setCartState(cartCopy);
			Helper.setStorage("cart", cartCopy);
			window.dataLayer.push({
				event: "add_to_cart",
				ecommerce: {
					currency: "",
					value: "",
					items: cartCopy,
				},
			});
			setSnackBarProps({
				openSnack: true,
				variant: "success",
				message: "Product Added in Card",
			});
		}
		getProducts();
	};
	const getData = (id) => {
		setLoading(false);
		const data = {
			per_page: perPage,
			current_page: currentPage,
			category_id: categoryId,
			user_id: id,
		};
		ApiHelper.post(APIURLConstants.get_products, data)
			.then((res) => {
				setLoading(false);
				console.log("res.data.result", res.data.result);
				setProducts(res.data.result.products);
			})
			.catch((error) => {
				setLoading(false);
			});
	};
	const getProducts = () => {
		if (state.user.get()) {
			getData(state.user.id.get());
		} else {
			const cart = Helper.getStorage("cart");
			let cartAsin = [];
			if (cart) {
				let cartCopy = [...cart];
				cartAsin = cartCopy.map((key) => key.asin);
			}
			setLoading(false);
			const data = {
				per_page: perPage,
				current_page: currentPage,
				category_id: categoryId,
				user_id: 0,
				cartAsin: cartAsin,
			};
			ApiHelper.post(APIURLConstants.get_products, data)
				.then((res) => {
					setLoading(false);
					setProducts(res.data.result.products);
				})
				.catch((error) => {
					setLoading(false);
				});
		}
	};
	const handleCategory = (catID) => {
		setCategoryId(catID);
	};
	const checkProductExist = (p_asin) => {
		if (state.user.get()) {
			if (p_asin && cartAdded && cartAdded.length > 0) {
				const cartStatus =
					cartAdded && cartAdded.find((key) => key.asin === p_asin);
				if (cartStatus !== undefined) {
					return cartStatus.cart_status_id;
				}
			}
			return 0;
		} else {
			const cart = Helper.getStorage("cart");
			if (cart) {
				let cartCopy = [...cart];
				if (cartCopy && cartCopy.length > 0) {
					let exist = cartCopy.some((item) => item.asin === p_asin);
					if (exist && exist !== undefined) {
						return 1;
					} else {
						return 0;
					}
				} else {
					return 0;
				}
			}
		}
	};

	const getProductCartDetails = () => {
		const productInfo = {
			user_id: state.user.id.get(),
		};
		ApiHelper.post(APIURLConstants.GET_PRODUCT_CART, productInfo)
			.then((res) => {
				if (res.data) {
					if (res.data.success) {
						setCartAdded(res.data.result.cart);
					} else {
						setCartAdded([]);
						// setEmailErr("The email has already been taken.");
						return;
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(async () => {
		getProducts();
	}, [perPage, currentPage, categoryId]);

	useEffect(() => {
		if (state.user.get()) {
			getProductCartDetails();
			getMyProducts();
		}
	}, []);

	const getMyProducts = () => {
		setLoading(true);
		const data = { user_id: state.user.id.get() };
		ApiHelper.post(APIURLConstants.GET_MY_PRODUCTS, data)
			.then((res) => {
				setMyProductCount(res.data.result.products.length);
				// setLoading(false);
				// setProducts(res.data.result.products);
				// setPlanState(res.data.result.products);
			})
			.catch((error) => {
				// setLoading(false);
				console.log(error);
			});
	};

	useEffect(() => {
		setCartCount(planState.products.attach(Downgraded).get().length);
	}, [planState && planState.products]);
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					justifyContent: "space-between",
					// flexWrap: { md: "wrap" },
					p: 1,
					paddingTop: 4,
					mx: { xs: 2, md: 7 },
				}}
			>
				<Box sx={{ display: "flex", paddingTop: 1 }}>
					<SearchIcon fontSize="large" sx={{ color: "tertiary.main", mx: 1 }} />
					<Typography fontSize={24} fontWeight={500} marginRight={1}>
						Find Products
					</Typography>
				</Box>
				<Box>
					<Typography
						fontSize={{ sx: 20, md: 26 }}
						textAlign={"center"}
						fontWeight={500}
						marginTop={0.5}
					>
						Pick a winning product now and start winning!
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: { xs: "center" },
					}}
				>
					<Category handleCategory={handleCategory} />
				</Box>
			</Box>

			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						// height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<>
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							flexDirection: { xs: "column", md: "row" },
							justifyContent: "center",
							paddingLeft: { xs: "0", md: "4em" },
						}}
					>
						{products.map((product, index) => {
							return (
								<ProductCard
									key={index}
									data={product}
									myAccount={false}
									addItem={addItem}
									addCartItem={addCartItem}
									checkProductExist={checkProductExist}
								/>
							);
						})}
					</Box>
					{snackBarProps.openSnack && (
						<Snackbars
							{...snackBarProps}
							autoHideDuration={200}
							onClose={() =>
								setSnackBarProps({ ...snackBarProps, openSnack: false })
							}
						></Snackbars>
					)}
				</>
			)}
		</>
	);
};
